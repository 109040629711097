import React, { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Map from "./Map";
import Trip from "./Trip";
import ArrivalBoard from "./ArrivalBoard";
import { BOARD_STATES } from "components/admin_v2/trips/stores/tripsStore";

const TripsMap = ({ tripsStore }) => {
  const cls = useStyles();
  const [state, actions] = tripsStore;
  const trips = state.trips;

  useEffect(() => {
    if (!state.map) return;

    state.map.resize();
  }, [state.boardState]);

  const showTripsMap = trips.length && !(ENV.RAILS_ENV === "test");

  const gridSize = () => {
    switch (state.boardState) {
      case BOARD_STATES.collapsed:
        return 2;
      case BOARD_STATES.half:
        return 4;
      default:
        return 8;
    }
  };

  const mapSize = () => {
    if (state.boardState == BOARD_STATES.collapsed && state.selectedTrip) {
      return 7;
    } else if (state.boardState == BOARD_STATES.collapsed) {
      return 10;
    } else if (state.boardState == BOARD_STATES.half && state.selectedTrip) {
      return 5;
    } else if (state.boardState == BOARD_STATES.half) {
      return 8;
    } else if (state.selectedTrip) {
      return 1;
    } else {
      return 4;
    }
  };

  return (
    <>
      <Box>
        <Paper className={cls.paper}>
          <Box className={cls.container}>
            <Grid container className={cls.grid}>
              <Grid item xs={gridSize()}>
                <ArrivalBoard tripsStore={tripsStore} />
              </Grid>
              {state.selectedTrip && (
                <Grid item xs={3} className={cls.tripWrapper}>
                  <Trip
                    tripInfo={state.selectedTrip}
                    onClose={actions.updateSelectedTrip}
                    tripsStore={tripsStore}
                  />
                </Grid>
              )}
              <Grid item xs={mapSize()} className={cls.mapWrapper}>
                {showTripsMap && <Map tripsStore={tripsStore} />}
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(0)
  },
  container: {
    width: "100%",
    minHeight: "250px",
    height: "calc(100vh - 250px)"
  },
  grid: {
    flexGrow: 1,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    paddingBottom: theme.spacing(2.3),
    height: "100%",
    borderTop: `2px ${theme.custom.LIGHT_GREY} solid`,
    "& .MuiGrid-item": {
      maxHeight: "100%",
      overflow: "auto"
    }
  },
  tripsListWrapper: {
    borderRight: `4px ${theme.palette.background.mediumGrey} solid`
  },
  gridItemWrapper: {
    borderRight: `4px ${theme.palette.background.mediumGrey} solid`
  },
  tripWrapper: {
    borderLeft: `4px ${theme.palette.background.mediumGrey} solid`
  },
  mapWrapper: {
    padding: theme.spacing(0.5)
  }
}));

export default TripsMap;
