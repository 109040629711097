import React, { useState, useMemo, useCallback } from "react";
import classNames from "classnames";
import { MenuItem, Select, Box, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useStoreState } from "easy-peasy";
import { some } from "lodash";
import { EventStatus } from "./EventHelpers";
import { ROUTE_STATUSES } from "context/admin_v2/appStore";
import I18n from "utils/i18n.js";

const CANCEL_EVENTS = {
  [ROUTE_STATUSES.onTime]: { labelPath: "cancel", eventType: "cancel", name: "cancel" },
  [ROUTE_STATUSES.late]: { labelPath: "late_cancel", eventType: "cancel", name: "cancel" },
  [ROUTE_STATUSES.started]: { labelPath: "no_show", eventType: "no_show", name: "no_show" }
};

const CANCEL_PASS_EVENTS = {
  [ROUTE_STATUSES.onTime]: { labelPath: "cancel_pass", eventType: "ok", name: "cancel_pass" },
  [ROUTE_STATUSES.late]: {
    labelPath: "late_cancel_pass",
    eventType: "ok",
    name: "late_cancel_pass"
  },
  [ROUTE_STATUSES.started]: { labelPath: "no_show", eventType: "no_show", name: "no_show" }
};

const isOkEvent = (eventType) => ["student_add", "ok"].includes(eventType);

const EventSimpleSelect = (props) => {
  const {
    tripType,
    eventType,
    disabled,
    onChange,
    date = null,
    startTime = null,
    schoolId = null,
    isOtherRoute = false,
    isUnassigned = false,
    dayOff = false,
    isTextVersion = false,
    withActivity = false,
    onChangeRequest = null,
    changeRequestId = null,
    children
  } = props;
  const { isUserAdmin, isFutureDate, isPastDate, routeStartStatus, readOnly } = useStoreState(
    (s) => s.app
  );
  const cls = useStyles(props);
  const clsSelect = classNames(cls.eventSelect);
  const [open, setOpen] = useState(false);

  const events = props.events || [];
  // get chosen option in case it's different from eventType, make sense for popups
  const chosenEventType = props.chosenEventType || eventType || "ok";
  const isFuture = isFutureDate(schoolId, date);
  const isPast = isPastDate(schoolId, date);
  // school users should have the ability to cancel one day passes as long as the new ride and old ride are more than 2 hours away.
  const cancelPassStatus =
    eventType === "student_add" && !isUserAdmin
      ? routeStartStatus(schoolId, date, startTime)
      : null;
  const isReadOnly = isUserAdmin
    ? isTextVersion
    : readOnly ||
      isTextVersion ||
      isPast ||
      (cancelPassStatus && cancelPassStatus !== ROUTE_STATUSES.onTime);
  const withReason = some(events, "explained");

  const mapOptions = (options) =>
    options.map((option) => (
      <MenuItem key={option.eventType} event={option.name} value={option.eventType}>
        {option.labelPath ? I18n.t(`student.event.${option.labelPath}`) : option.label}
      </MenuItem>
    ));

  // options for assignment with no changes
  const regularOptions = () => {
    let options = [{ labelPath: "label.ok", eventType, name: "ok" }];
    const routeStatus = isFuture
      ? ROUTE_STATUSES.onTime
      : isPast
      ? ROUTE_STATUSES.started
      : cancelPassStatus || routeStartStatus(schoolId, date, startTime);
    if (!isUnassigned) {
      options.push(
        eventType === "student_add" || eventType === "one_off"
          ? CANCEL_PASS_EVENTS[routeStatus]
          : CANCEL_EVENTS[routeStatus]
      );
    }
    if (!cancelPassStatus) {
      if (
        (routeStatus == ROUTE_STATUSES.onTime || isUserAdmin) &&
        !(isOtherRoute && eventType === "student_add")
      )
        options.push({ labelPath: "change", eventType: "change", name: "change" });
      if (
        !isUnassigned &&
        !isOtherRoute &&
        withActivity &&
        routeStatus !== ROUTE_STATUSES.started
      ) {
        options.push({
          labelPath: routeStatus === ROUTE_STATUSES.late ? "late_activity" : "activity",
          name: "activity",
          eventType: "activity"
        });
      }
      if (isOtherRoute && eventType !== "student_add")
        options.push({ labelPath: "remove", eventType: "remove", name: "remove" });
    }
    return mapOptions(options);
  };

  // options for assignment with changes
  const undoOptions = () => {
    const event = events.find((e) => e.event_type === eventType);
    const eventLabel = event?.event_time === ROUTE_STATUSES.late ? `late_${eventType}` : eventType;
    let options = [
      { labelPath: "label.ok", eventType, name: "ok" },
      {
        label: I18n.t("student.event.label.undo", { event: I18n.t(`student.event.${eventLabel}`) }),
        name: eventLabel,
        eventType: "ok"
      }
    ];
    return mapOptions(options);
  };

  const options = useMemo(
    () => (isOkEvent(eventType) || eventType === "one_off" ? regularOptions() : undoOptions()),
    [open, eventType]
  );
  const onOpen = useCallback(() => setOpen(true), []);
  const onClose = useCallback(() => setOpen(false), []);

  const updateEventType = (e, child) => {
    setOpen(false);
    onChange(e.target.value, child?.props?.event);
  };

  if (cancelPassStatus === ROUTE_STATUSES.late) {
    return (
      <Tooltip title={I18n.t("student.event.tooltip.late_cancel")}>
        <Select
          id={`${tripType}-event`}
          className={clsSelect}
          value="ok"
          disabled
          variant="outlined"
          fullWidth
          classes={{ select: clsSelect }}
        >
          <MenuItem key="ok" event="ok" value="ok">
            {I18n.t("student.event.label.ok")}
          </MenuItem>
        </Select>
      </Tooltip>
    );
  }

  // options for read only mode (both users and change itself)
  if (isReadOnly || withReason || eventType === "change_req" || (dayOff && !isOtherRoute)) {
    const okItem = dayOff ? I18n.t("student.event.day_off") : null;
    return (
      <div className={isTextVersion ? {} : clsSelect} value={eventType || "ok"}>
        {eventType !== "ok" ? (
          <EventStatus
            events={events}
            changeRequestId={changeRequestId}
            onChangeRequest={onChangeRequest}
          />
        ) : (
          okItem
        )}
      </div>
    );
  }

  return (
    <Box sx={{ display: "inline-flex", alignItems: "center" }}>
      <Select
        id={`${tripType}-event`}
        className={clsSelect}
        value={chosenEventType}
        onChange={updateEventType}
        disabled={disabled || readOnly}
        variant="outlined"
        fullWidth={true}
        classes={{ select: clsSelect }}
        onOpen={onOpen}
        onClose={onClose}
      >
        {options}
      </Select>
      {children}
    </Box>
  );
};

// clear styles if we don't need to color dropdown
const useStyles = makeStyles((theme) => ({
  ok: {
    color: theme.custom.MEDIUM_DARK_GREY,
    minHeight: theme.spacing(4)
  },
  cancel: {
    color: theme.custom.YELLOW,
    fontWeight: theme.custom.SEMI_BOLD
  },
  no_show: {
    color: theme.custom.RED,
    fontWeight: theme.custom.SEMI_BOLD
  },
  change: {
    color: theme.custom.YELLOW,
    fontWeight: theme.custom.SEMI_BOLD
  },
  remove: {
    color: theme.custom.RED,
    fontWeight: theme.custom.SEMI_BOLD
  },
  eventSelect: {
    minWidth: "65px",
    width: (props) => (props.fullWidth ? "100%" : "auto"),
    marginRight: (props) => (props.children ? theme.spacing(1) : 0),
    fontSize: "14px",
    lineHeight: "normal",
    textAlign: "left",
    backgroundColor: (props) => (props.withoutBg ? "inherit" : theme.custom.WHITE),
    "&.redEvent": {
      color: theme.custom.RED,
      fontWeight: theme.custom.SEMI_BOLD
    },
    "&.yellowEvent": {
      color: theme.custom.YELLOW,
      fontWeight: theme.custom.SEMI_BOLD
    },
    "& .Mui-disabled": {
      "-webkitTextFillColor": "inherit"
    }
  }
}));

export default EventSimpleSelect;
